<script>
import { mapActions, mapState } from "vuex";
import ConnectedTSPItem from "@/components/tsp/ConnectedTSPItem.vue";
import MainSelect from "@/components/helpers/MainSelect.vue";
import ConnectedTSPNotice from "@/components/tsp/ConnectedTSPNotice.vue";
import MainLoader from "@/components/helpers/MainLoader.vue";

export default {
  name: "ConnectedTSPPartner",

  components: {
    MainSelect,
    ConnectedTSPItem,
    ConnectedTSPNotice,
    MainLoader,
  },

  data() {
    return {
      isLoading: false,

      params: {
        period: {
          id: "all",
          value: "all",
          name: "За все время",
        },
      },

      periods: [
        {
          id: "all",
          value: "all",
          name: "За все время",
        },
        {
          id: "month",
          value: "month",
          name: "За текущий месяц",
        },
      ],
    };
  },

  watch: {
    "params.period": {
      deep: true,
      handler() {
        this.isLoading = true;
        this.loadCompanies({ isCurrentMonth: this.isCurrentMonth }).finally(
          () => {
            this.isLoading = false;
          }
        );
      },
    },
  },

  computed: {
    ...mapState({
      companies: (state) => state.tsp.companies,
    }),

    isCurrentMonth() {
      return this.params.period.id === "month";
    },

    isCompaniesNotExist() {
      return this.companies.length === 0 && this.params.period.id === "all";
    },
  },

  methods: {
    ...mapActions({
      loadCompanies: "tsp/loadCompanies",
    }),
  },

  mounted() {
    this.isLoading = true;
    this.loadCompanies({ isCurrentMonth: this.isCurrentMonth }).finally(() => {
      this.isLoading = false;
    });
  },
};
</script>

<template>
  <div class="connected-tsp-partner">
    <MainLoader v-if="isLoading" />

    <template v-if="isCompaniesNotExist && !isLoading">
      <ConnectedTSPNotice />
    </template>

    <template v-else-if="!isLoading">
      <MainSelect
        class="connected-tsp-partner__selector"
        :selected-option.sync="params.period"
        :options="periods"
        :style-type="'background-white'"
      />

      <div v-if="companies.length" class="connected-tsp-partner__list">
        <ConnectedTSPItem
          v-for="company in companies"
          :key="company.inn"
          :company="company"
        />
      </div>

      <template v-else>
        <p class="connected-tsp-partner__text">
          По выбранным фильтрам ТСП не найдены
        </p>
      </template>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.connected-tsp-partner {
  &__selector {
    margin-bottom: 40px;
    max-width: 240px;
  }

  & ::v-deep .main-select {
    max-width: none;

    &__items {
      max-width: none;
    }
  }

  &__text {
    @include body-1;
    color: $dark-primary;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

@media (max-width: 1440px) {
  .connected-tsp-partner {
    &__list {
      max-width: 100%;
    }
  }
}

@media (max-width: 900px) {
  .connected-tsp-partner {
    &__selector {
      margin-bottom: 32px;
    }
  }
}
</style>
