<script>
import { format } from "date-fns";

export default {
  components: {},

  props: {
    company: {
      type: Object,
      required: true,
    },
  },

  computed: {
    formattedDate() {
      return this.company.created_at
        ? format(new Date(this.company.created_at), "dd.MM.yyyy")
        : "-";
    },

    formattedTurnover() {
      return `${this.company.sum.toLocaleString()} ₽`;
    },
  },
};
</script>

<template>
  <div class="connected-tsp-rcpos-item">
    <div class="connected-tsp-rcpos-item__tsp tsp">
      <p class="tsp__name">{{ company.name }}</p>
      <p class="tsp__date">{{ formattedDate }}</p>
    </div>

    <div class="connected-tsp-rcpos-item__turnover turnover">
      <span class="icon-coin-3" />
      <div class="turnover__value">
        <p>Оборот ТСП:</p>
        <p>{{ formattedTurnover }}</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.connected-tsp-rcpos-item {
  max-width: 1200px;
  padding: 32px;
  background-color: $light-primary;
  border-radius: 16px;

  &:not(:last-child) {
    margin-bottom: 32px;
  }

  .tsp {
    display: flex;
    align-items: center;
    color: $dark-primary;
    padding: 0 0 24px;
    border-bottom: 1px solid $outline-light;
    margin-bottom: 24px;

    &__name {
      @include body-1-bold;
      margin-right: 24px;
      width: 100%;
    }

    &__date {
      @include body-1;
      color: $dark-primary;
    }
  }

  .turnover {
    display: flex;
    align-items: center;
    gap: 8px;

    .icon-coin-3 {
      width: 20px;
      height: 20px;
      background-color: $dark-primary;
    }

    &__value {
      display: flex;
      align-items: center;
      gap: 8px;

      > p {
        @include body-1;
        color: $dark-primary;
      }
    }
  }
}

@media (max-width: 1440px) {
  .connected-tsp-rcpos-item {
    padding: 32px;

    .tsp {
      padding: 0 0 24px;
    }
  }
}

@media (max-width: 900px) {
  .connected-tsp-rcpos-item {
    padding: 24px;

    .tsp {
      padding: 0 0 16px;
      margin-bottom: 16px;
    }
  }
}

@media (max-width: 576px) {
  .connected-tsp-rcpos-item {
    padding: 20px;
  }
}
</style>
