export default function formatTSPTariff(tariffCode) {
  switch (tariffCode) {
    case "lite_p":
      return "Лайт";

    case "promo_p":
      return "Промо";

    case "pluspro_p":
      return "Про+";
  }
}
