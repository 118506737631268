<script>
import { mapActions, mapState } from "vuex";
import MainSelect from "@/components/helpers/MainSelect.vue";
import ConnectedTSPNotice from "@/components/tsp/ConnectedTSPNotice.vue";
import MainLoader from "@/components/helpers/MainLoader.vue";
import ConnectedTSPRCPOSItem from "./ConnectedTSPRCPOSItem.vue";

export default {
  name: "ConnectedTSPRCPOS",

  components: {
    MainSelect,
    ConnectedTSPNotice,
    MainLoader,
    ConnectedTSPRCPOSItem,
  },

  data() {
    return {
      isLoading: false,

      params: {
        period: {
          id: "all",
          value: "all",
          name: "За все время",
        },
      },

      periods: [
        {
          id: "all",
          value: "all",
          name: "За все время",
        },
        {
          id: "month",
          value: "month",
          name: "За текущий месяц",
        },
      ],
    };
  },

  watch: {
    "params.period": {
      deep: true,
      handler() {
        this.isLoading = true;
        this.loadCompaniesRCPOS({
          isCurrentMonth: this.isCurrentMonth,
        }).finally(() => {
          this.isLoading = false;
        });
      },
    },
  },

  computed: {
    ...mapState({
      companiesRCPOS: (state) => state.tsp.companiesRCPOS,
    }),

    isCurrentMonth() {
      return this.params.period.id === "month";
    },

    isCompaniesNotExist() {
      return (
        this.companiesRCPOS.length === 0 && this.params.period.id === "all"
      );
    },
  },

  methods: {
    ...mapActions({
      loadCompaniesRCPOS: "tsp/loadCompaniesRCPOS",
    }),
  },

  mounted() {
    this.isLoading = true;
    this.loadCompaniesRCPOS({ isCurrentMonth: this.isCurrentMonth }).finally(
      () => {
        this.isLoading = false;
      }
    );
  },
};
</script>

<template>
  <div class="connected-tsp-rcpos">
    <MainLoader v-if="isLoading" />

    <template v-if="isCompaniesNotExist && !isLoading">
      <ConnectedTSPNotice />
    </template>

    <template v-else-if="!isLoading">
      <MainSelect
        class="connected-tsp-rcpos__selector"
        :selected-option.sync="params.period"
        :options="periods"
        :style-type="'background-white'"
      />

      <div v-if="companiesRCPOS.length" class="connected-tsp-rcpos__list">
        <ConnectedTSPRCPOSItem
          v-for="company in companiesRCPOS"
          :key="company.inn"
          :company="company"
        />
      </div>

      <template v-else>
        <p class="connected-tsp-rcpos__text">
          По выбранным фильтрам ТСП не найдены
        </p>
      </template>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.connected-tsp-rcpos {
  &__selector {
    margin-bottom: 40px;
    max-width: 240px;
  }

  & ::v-deep .main-select {
    max-width: none;

    &__items {
      max-width: none;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__text {
    @include body-1;
    color: $dark-primary;
  }
}

@media (max-width: 1440px) {
  .connected-tsp-rcpos {
    &__list {
      max-width: 100%;
    }
  }
}

@media (max-width: 900px) {
  .connected-tsp-rcpos {
    &__selector {
      margin-bottom: 32px;
    }
  }
}
</style>
