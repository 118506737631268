<script>
import MainButtonIcon from "@/package/components/MainButtonIcon.vue";

export default {
  components: { MainButtonIcon },

  props: {
    tradePoints: {
      type: Array,
      required: true,
    },
  },

  methods: {
    formatName(name) {
      return name ? name : "-";
    },

    formatAddress(address) {
      return address ? address : "-";
    },

    formatIncome(income) {
      return income !== null ? `${income.toLocaleString()} ₽` : "-";
    },

    redirectToTradePoint(url) {
      window.open(url);
    },
  },
};
</script>

<template>
  <div class="tsp-trade-points">
    <div class="tsp-trade-points__title">
      <h5>Название торговой точки</h5>
      <h5>Оцифровано</h5>
      <h5>Город</h5>
      <h5>Оборот ТСП</h5>
    </div>

    <div class="tsp-trade-points__list">
      <div
        v-for="(tradePoint, index) in tradePoints"
        :key="index"
        class="tsp-trade-points__item"
        :class="{ 'tsp-trade-points__item_hovered': tradePoint.is_active }"
      >
        <div class="tsp-trade-points__name">
          <p>{{ formatName(tradePoint.name) }}</p>
        </div>
        <div class="tsp-trade-points__wrapper">
          <span>Оцифровано:</span>
          <p v-if="tradePoint.invited_count !== null">
            {{ tradePoint.invited_count }}
          </p>
        </div>
        <div class="tsp-trade-points__wrapper">
          <span>Город:</span>
          <p>{{ formatAddress(tradePoint.city) }}</p>
        </div>
        <div class="tsp-trade-points__wrapper">
          <span>Оборот ТСП:</span>
          <p v-if="tradePoint.income_amount !== null">
            {{ formatIncome(tradePoint.income_amount) }}
          </p>
        </div>
        <MainButtonIcon
          v-if="tradePoint.is_active"
          class="tsp-trade-points__button-link"
          @click="redirectToTradePoint(tradePoint.link)"
        >
          <span class="icon-link-open" />
        </MainButtonIcon>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.tsp-trade-points {
  &__title,
  &__item {
    display: grid;
    grid-template-columns: 1fr 120px 220px 120px 24px;
    column-gap: 16px;
    padding: 12px 16px;
  }

  &__title {
    h5 {
      @include body-1;
      color: $dark-sixth;
    }
  }

  &__item {
    span {
      display: none;
    }

    p,
    span {
      @include body-1;
      color: $dark-primary;
    }

    &_hovered {
      &:hover {
        background-color: $light-second;

        .icon-link-open {
          background-color: $dark-fifth;
        }
      }
    }
  }

  &__button-link {
    .icon-link-open {
      display: block !important;
      width: 24px;
      height: 24px;
      background-color: transparent;

      &:hover {
        background-color: $blue;
      }
    }
  }
}

@media (max-width: 1440px) {
  .tsp-trade-points {
    &__title {
      display: none;
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &__item {
      grid-template-columns: 1fr;
      padding: 20px;
      border: 1px solid $outline-light;
      border-radius: 8px;
      position: relative;

      span {
        display: block;
      }
    }

    &__name {
      margin-bottom: 20px;

      > p {
        @include body-1-bold;
      }
    }

    &__wrapper {
      display: grid;
      grid-template-columns: 120px 1fr;
      column-gap: 16px;

      &:not(:last-of-type) {
        margin-bottom: 16px;
      }
    }

    &__button-link {
      position: absolute;
      right: 20px;
      top: 20px;
    }
  }
}

@media (max-width: 900px) {
  .tsp-trade-points {
    &__item {
      padding: 16px;
      background-color: $light-second;
    }

    &__wrapper {
      &:not(:last-of-type) {
        margin-bottom: 12px;
      }
    }

    &__name {
      margin-bottom: 16px;
    }

    &__button-link {
      right: 16px;
      top: 16px;

      .icon-link-open {
        background-color: $dark-fifth;
      }
    }
  }
}
</style>
