<script>
import MainInfo from "@/components/helpers/MainInfo.vue";

export default {
  components: {
    MainInfo,
  },
};
</script>

<template>
  <div class="connected-tsp-notice">
    <p>Вы пока не подключили ни одного ТСП</p>

    <MainInfo
      class="connected-tsp-notice__info"
      :description="'ТСП - торгово-сервисное предприятие, например, кафе или магазин. Совершайте сделки с предприятиями и получайте процент с каждой их транзакции.'"
    />
  </div>
</template>

<style lang="scss" scoped>
.connected-tsp-notice {
  p {
    margin-bottom: 24px;
    @include body-1;
    color: $dark-third;
  }

  &__info {
    max-width: 480px;
  }
}
</style>
