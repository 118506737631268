<script>
import { format } from "date-fns";
import TSPTariffPreview from "@/components/tsp/TSPTariffPreview.vue";
import TSPTradePoints from "@/components/tsp/TSPTradePoints.vue";

export default {
  components: {
    TSPTariffPreview,
    TSPTradePoints,
  },

  props: {
    company: {
      type: Object,
      required: true,
    },
  },

  computed: {
    formattedDate() {
      return this.company.tariff_start_at
        ? format(new Date(this.company.tariff_start_at), "dd.MM.yyyy")
        : "-";
    },
  },
};
</script>

<template>
  <div class="connected-tsp-item">
    <div
      class="connected-tsp-item__tsp tsp"
      :class="{
        tsp_empty: !company.trade_points || company.trade_points.length === 0,
      }"
    >
      <div class="tsp__name">
        <p v-if="company.name">{{ company.name }}</p>
      </div>
      <div class="tsp__wrapper">
        <div class="tsp__tariff">
          <TSPTariffPreview
            v-if="company.tariff_code"
            :tariff-code="company.tariff_code"
          />
        </div>
        <div class="tsp__date">
          <p>{{ formattedDate }}</p>
        </div>
      </div>
    </div>

    <div
      v-if="company.trade_points && company.trade_points.length"
      class="connected-tsp-item__trade-points trade-points"
    >
      <TSPTradePoints :trade-points="company.trade_points" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.connected-tsp-item {
  max-width: 1200px;
  padding: 32px 16px;
  background-color: $light-primary;
  border-radius: 16px;

  .tsp {
    display: flex;
    align-items: center;
    color: $dark-primary;
    padding: 0 16px 24px;
    border-bottom: 1px solid $outline-light;
    margin-bottom: 16px;

    &__name {
      @include body-1-bold;
      margin-right: 24px;
      width: 100%;
    }

    &__wrapper {
      display: flex;
      align-items: center;
    }

    &__tariff {
      margin-right: 16px;
    }

    &__name,
    &__tariff,
    &__date {
      span {
        display: none;
        margin-bottom: $space-xs;
        @include body-1;
        color: $dark-sixth;
      }
    }

    &_empty {
      border: none;
      padding-bottom: 0 !important;
      margin-bottom: 0 !important;
    }
  }
}

@media (max-width: 1440px) {
  .connected-tsp-item {
    padding: 32px;

    .tsp {
      padding: 0 0 24px;

      &__name {
        margin-right: 16px;
      }

      &__tariff {
        margin-right: 12px;
      }
    }
  }
}

@media (max-width: 900px) {
  .connected-tsp-item {
    padding: 24px;

    .tsp {
      &__name {
        margin-right: 12px;
      }
    }
  }
}

@media (max-width: 575px) {
  .connected-tsp-item {
    padding: 20px;

    .tsp {
      &__wrapper {
        flex-direction: column;
        align-items: flex-start;
      }

      &__tariff {
        margin-right: 0;
        margin-bottom: 4px;
      }
    }
  }
}
</style>
