<script>
import MainTabs from "@/components/helpers/MainTabs.vue";
import ConnectedTSPPartner from "@/components/tsp/ConnectedTSPPartner.vue";
import ConnectedTSPRCPOS from "@/components/tsp/ConnectedTSPRCPOS.vue";

export default {
  name: "ConnectedTSP",

  components: {
    ConnectedTSPRCPOS,
    ConnectedTSPPartner,
    MainTabs,
  },

  data() {
    return {
      selectedTab: {
        id: "kp",
        title: "RC CITY",
        icon: "icon-shop",
      },
      tabs: [
        {
          id: "kp",
          title: "RC CITY",
          icon: "icon-logo-yellow-rc-city",
          notActiveIcon: "icon-logo-gray-rc-city",
        },
        {
          id: "rcpos",
          title: "RC POS",
          icon: "icon-logo-purple-rc-pos",
          notActiveIcon: "icon-logo-gray-rc-pos",
        },
      ],
    };
  },
};
</script>

<template>
  <div class="connected-tsp default-content-padding">
    <MainTabs
      class="connected-tsp__tabs"
      :selected-tab.sync="selectedTab"
      :tabs="tabs"
      tab-row
    />

    <ConnectedTSPPartner v-if="selectedTab.id === 'kp'" />

    <ConnectedTSPRCPOS v-if="selectedTab.id === 'rcpos'" />
  </div>
</template>

<style lang="scss" scoped>
.connected-tsp {
  padding-bottom: 60px;

  &__tabs {
    max-width: 440px;
    margin-bottom: 40px;
  }
}

@media (max-width: 900px) {
  .connected-tsp {
    &__tabs {
      max-width: none;
      margin-bottom: 32px;
    }
  }
}
</style>
