<script>
import formatTSPTariff from "@/package/helpers/format-tsp-tariff.js";

export default {
  props: {
    tariffCode: {
      type: String,
      required: true,
    },
  },

  computed: {
    formattedTSPTariff() {
      return formatTSPTariff(this.tariffCode);
    },

    previewClass() {
      return `tsp-tariff-preview_${this.tariffCode}`;
    },
  },
};
</script>

<template>
  <div class="tsp-tariff-preview" :class="previewClass">
    <p>{{ formattedTSPTariff }}</p>
  </div>
</template>

<style lang="scss" scoped>
.tsp-tariff-preview {
  padding: $space-xs $space-s;
  border-radius: $space-s;
  @include caption-1;
  width: fit-content;
  height: fit-content;
  white-space: nowrap;

  &_lite_p {
    background-color: $light-second;
    color: $dark-primary;
  }

  &_promo_p {
    background-color: $gold-main;
    color: $dark-primary;
  }

  &_pluspro_p {
    color: $light-primary;
    background: linear-gradient(
      163.68deg,
      #86759c 0%,
      #766987 54.69%,
      #282330 100%
    );
  }
}
</style>
